import React from 'react';
import { Link } from 'react-router-dom';
import img from './logo-primeurspassion.png';
import styles from './Logo.module.scss';

interface Props {
	className?: string;
}

function Logo({ className }: Props) {
	return (
		<Link
			to="/"
			title="Aller à l'accueil"
			className={[styles.link, className].filter(Boolean).join(' ')}
		>
			<img className={styles.logo} src={img} alt="Primeurs Passion" />
		</Link>
	);
}

export default Logo;
