import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { addProduitToPanier } from '../../../store/panier/actions';
import { LignePanier } from '../../../store/panier/types';
import { Produit } from '../../../types/produit';
import { photosProduitsSizes } from '../../../utils/imgSizes';
import request from '../../../utils/request';
import BtnAjoutPanier from '../../BtnAjoutPanier/BtnAjoutPanier';
import BtnFavori from '../../BtnFavori/BtnFavori';
import EditCommentaire from '../../Commentaire/EditCommentaire/EditCommentaire';
import Img from '../../Img/Img';
import Label from '../../Label/Label';
import PlusMoinsPanier from '../../PlusMoinsPanier/PlusMoinsPanier';
import Prix from '../../Prix/Prix';
import styles from './VignetteProduit.module.scss';

const setProduitFavoriMutation = loader('./setProduitFavori.graphql');

export interface Props extends Produit {
	lignesPanier: LignePanier[];
	addProduitToPanier: typeof addProduitToPanier;
}

export function _VignetteProduit({
	nom,
	tarif,
	ucParDefaut,
	code,
	labels,
	categorie,
	isFavoriteForMe,
	imageSrc,
	facteurU,
	addProduitToPanier,
	lignesPanier,
}: Props) {
	const [isFavori, setIsFavori] = useState(isFavoriteForMe);
	// const [isPrecommande, setIsPrecommande] = useState(false);

	const isInPanier = !!lignesPanier.find((l) => l.code === code);

	const prixUnite = tarif ? +tarif.prix : 0;

	const uf = tarif && tarif.uf;

	function ajouterAuxFavoris() {
		setIsFavori(!isFavori);
		request(setProduitFavoriMutation, { favori: !isFavori, codeProduit: code });
	}

	function ajouterAuPanier() {
		const uc = uf || ucParDefaut;
		addProduitToPanier({
			nom,
			code,
			uc,
			uf: uf || 'K',
			imageSrc,
			categorie,
			prixUnite,
			facteurU,
			interdit: false,
			disabled: false,
			nouveauPrix: false,
			labels: labels.map((l) => l.abreviation),
			quantite: (uc === 'U' && facteurU) || 1,
		});
	}

	return (
		<div
			className={[styles.wrapper, isInPanier && styles.inPanier]
				.filter(Boolean)
				.join(' ')}
		>
			{/* Ajout / Suppression des favoris */}
			<BtnFavori
				title={
					isFavori
						? 'Enlever le produit de mes favoris'
						: 'Ajouter le produit à mes favoris'
				}
				className={styles.btnFavori}
				onClick={ajouterAuxFavoris}
				checked={isFavori}
			/>

			<div className={styles.left}>
				{/* Photo du produit */}
				<Img
					alt={nom}
					fallback="jpg"
					src={imageSrc}
					className={styles.photo}
					otherSizes={photosProduitsSizes}
					mediaQueries={[{ width: styles.photoWidth }]}
				/>
			</div>

			<div className={styles.right}>
				{/* Nom */}
				<h1 className={styles.nom}>{nom}</h1>

				{/* Labels */}
				<div>
					{labels.map(
						({ abreviation }, i) =>
							abreviation !== 'DP' && <Label key={i}>{abreviation}</Label>,
					)}
					&nbsp;
				</div>

				{/* Prix unitaire */}
				<Prix className={styles.prix} prix={prixUnite} unite={uf || 'K'} />
			</div>

			{/* Bottom */}
			<div className={styles.bottom}>
				<p className={styles.code}>{code}</p>

				{isInPanier ? (
					<>
						<EditCommentaire codeProduit={code} />
						<PlusMoinsPanier codeProduit={code} className={styles.plusMoins} />
					</>
				) : (
					<>
						{labels.map(
							({ abreviation }, i) =>
								abreviation === 'DP' && (
									<p className={styles.code2} key={i}>
										Disponible en précommande
									</p>
								),
						)}
						<BtnAjoutPanier
							title="Ajouter le produit au panier"
							onClick={ajouterAuPanier}
							className={styles.btnPanier}
						/>
					</>
				)}
			</div>
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, { addProduitToPanier })(
	_VignetteProduit,
);
