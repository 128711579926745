import React from 'react';
import { useTitle } from '../../hooks/useTitle';
import { thinSpace } from '../../utils/french';
import { telephone } from '../../utils/telephone';
import Button from '../Button/Button';
import styles from './Contact.module.scss';
import { ReactComponent as InstagramSVG } from './instagram.svg';

const email = 'televente@primeurspassion.fr';
const adresse = '8-10 Rue des Lancés, 94310 Orly';
const gmap = 'https://goo.gl/maps/nozSZiQ3NRmH2vyZA';
const horaires = '6j/7 – 24h/24';
const site = 'http://primeurspassion.fr/';
// const facebook = 'https://www.facebook.com/BermudesRungis/';
const instagram = 'https://www.instagram.com/primeurspassion/';

export function Contact() {
	useTitle('Contact');

	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>Nous contacter</h1>

			<p>
				<strong>Par téléphone{thinSpace}:</strong>{' '}
				<a target="_blank" rel="noopener noreferrer" href={'tel:' + telephone}>
					{telephone}
				</a>{' '}
				({horaires})
			</p>

			<p>
				<strong>Par email{thinSpace}:</strong>{' '}
				<a target="_blank" rel="noopener noreferrer" href={'mailto:' + email}>
					{email}
				</a>
			</p>

			<p>
				<strong>Par courrier{thinSpace}:</strong>{' '}
				<a target="_blank" rel="noopener noreferrer" href={gmap}>
					{adresse}
				</a>
			</p>

			<p className={styles.links}>
				<a
					href={site}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.websiteLink}
				>
					<Button outline>Découvrez notre site internet</Button>
				</a>

				{/* <a
					href={facebook}
					target="_blank"
					rel="noopener noreferrer"
					title="Facebook"
					className={styles.social}
				>
					<FacebookSVG />
				</a> */}

				<a
					href={instagram}
					target="_blank"
					rel="noopener noreferrer"
					title="Instagram"
					className={styles.social}
				>
					<InstagramSVG />
				</a>
			</p>
		</div>
	);
}

export default Contact;
