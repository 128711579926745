import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { useTitle } from '../../hooks/useTitle';
import { AppState } from '../../store';
import { Produit } from '../../types/produit';
import request from '../../utils/request';
import OrderBy from '../OrderBy/OrderBy';
import TableHeader from '../Table/TableHeader/TableHeader';
import TablePagination from '../Table/TablePagination/TablePagination';
import TableWrapper from '../Table/TableWrapper/TableWrapper';
import ListeProduits from './ListeProduits/ListeProduits';
import formatDate from 'dateformat';

const query = loader('./getFavoriteProducts.graphql');
const mutationSetFavorite = loader('./setProduitFavorite.graphql');
const itemsPerPage = 10;

export interface Props extends RouteComponentProps {
	codeClient?: string;
}

export function _ProduitsFavoris({ codeClient, location }: Props) {
	useTitle('Mes produits favoris');

	const [produits, setProduits] = useState<Produit[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const date = formatDate(new Date(), 'yyyy-mm-dd');

	const searchParams = new URLSearchParams(location.search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';

	function setProduitFavori(codeProduit: string, isFavorite: boolean) {
		const newProduits = produits.map((p) => {
			if (codeProduit === p.code) p.isFavoriteForMe = !isFavorite;
			return p;
		});
		setProduits(newProduits);
		request(mutationSetFavorite, { favori: !isFavorite, codeProduit });
	}

	// Fetch des produits
	useEffect(() => {
		if (!orderBy || !order) return;

		let isMounted = true;
		setLoading(true);

		const params = {
			codeClient,
			skip: itemsPerPage * (pageIndex - 1),
			first: itemsPerPage,
			orderBy: `${orderBy}_${order}`,
			date: date,
		};

		type TResponse = { countProduits: number; produits: Produit[] };
		request<TResponse>(query, params)
			.then(({ produits, countProduits }) => {
				if (!isMounted) return;
				setProduits(produits);
				setTotalCount(countProduits);
				setError('');
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [location.key, order, orderBy, codeClient, pageIndex, date]);

	if (!orderBy || !order) {
		return <Redirect to="/produits-favoris?orderBy=slug&order=ASC" />;
	}

	return (
		<TableWrapper>
			<TableHeader>
				<OrderBy fields={[{ name: 'Nom', slug: 'slug' }]} />
			</TableHeader>

			<ListeProduits
				error={error}
				loading={loading}
				produits={produits}
				setProduitFavori={setProduitFavori}
			/>

			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={totalCount}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { codeClient: state.compte.code };
}

export default withRouter(connect(mapStateToProps)(_ProduitsFavoris));
